import {
  AssignmentTwoTone,
  CelebrationTwoTone,
  HourglassFullTwoTone,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FloatingVideo from "src/components/FloatingVideo";
import { useCurrentUser } from "src/SessionBoundary";
import SliderExplanationScreen, {
  SliderExplanationStepProp,
} from "../SliderExplanationScreen";
import BackgroundSurveyView from "./BackgroundSurveyView";
import LoadingTransitionScreen from "./LoadingTransitionScreen";

const ApplicationScreen = () => {
  const user = useCurrentUser();
  const [playVideo, setPlayVideo] = useState(false);
  const handleStart = () => {
    setPlayVideo(true); // Start playing the video after user interaction
  };
  const navigate = useNavigate();
  const [step, setStep] = useState<
    "pending" | "processing_eligible" | "finished_eligible"
  >("pending");

  const steps: SliderExplanationStepProp[] = [
    {
      type: "component",
      icon: CelebrationTwoTone,
      title: "You pre-qualify!",
      titleProps: { fontWeight: "bold" },
      body: "Based on the info you shared, you can qualify for a training scholarship!",
    },
    {
      type: "component",
      icon: AssignmentTwoTone,
      title: "From YOUR Side",
      titleProps: { fontWeight: "bold" },
      body: "Keep going! We admit on a rolling basis, so the sooner you finish, the sooner you can start training.",
    },
    {
      type: "component",
      icon: HourglassFullTwoTone,
      title: "From OUR side",
      titleProps: { fontWeight: "bold" },
      body: "We’ll review your background to confirm all the information you provided is accurate.",
    },
  ];

  const handleFinish = (isEligible: boolean) => {
    if (!isEligible) navigate("/", { replace: true });
    else {
      setStep("processing_eligible");
    }
  };

  if (step === "processing_eligible") {
    return (
      <LoadingTransitionScreen
        variant="rocket"
        onAnimationEnd={() => setStep("finished_eligible")}
        messages={[
          t("Processing your information..."),
          t("Connecting you with scholarships..."),
          t("Verifying your eligibility..."),
        ]}
      />
    );
  }

  if (step === "finished_eligible") {
    return (
      <SliderExplanationScreen
        finishButtonProps={{
          onFinish: () =>
            navigate("/onboarding/success-plan", { replace: true }),
        }}
        steps={steps}
      />
    );
  }

  return (
    <Box onClick={handleStart}>
      <BackgroundSurveyView user={user} onFinish={handleFinish} />
      <FloatingVideo
        videoUrl="https://f004.backblazeb2.com/file/emerge-courses/CDL/videos/admissions/IntroAdmissionsVideo.mp4"
        playVideo={playVideo}
      />
    </Box>
  );
};

export default ApplicationScreen;
