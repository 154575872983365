import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { School } from "src/types/School";

type SchoolCardProps = CardProps & {
  school: School;
  onSelect?: () => void;
  selected?: boolean;
};

const SchoolCard: React.FC<SchoolCardProps> = ({
  school,
  onSelect,
  selected = false,
  ...rest
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Card
      {...rest}
      onClick={onSelect}
      sx={{
        cursor: "pointer",
        border: selected
          ? `2px solid ${theme.palette.primary.main}`
          : `1px solid ${theme.palette.divider}`,
      }}
    >
      <CardHeader
        title={`${school.city}, ${school.state}`}
        subheader={`${school.zipcode}`}
      />
      <CardContent>
        <Typography variant="body2">
          <strong>{t("Requirements:")}</strong>
        </Typography>
        <List>
          {school.requirements.map((requirement) => (
            <ListItem key={requirement}>
              <Typography variant="body2">{requirement}</Typography>
            </ListItem>
          ))}
        </List>

        <Typography variant="body2">
          <strong>{t("Available Training Schedules:")}</strong>
        </Typography>
        <List>
          {school.trainingSchedules.map((schedule) => (
            <ListItem key={schedule}>
              <Typography variant="body2">{schedule}</Typography>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default SchoolCard;
