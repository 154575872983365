import { CalendarMonth, Rocket } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { isBefore } from "date-fns";
import Lottie from "lottie-react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Confetti from "src/assets/Lotties/Confetti.json"; // Import the confetti Lottie JSON
import { ReactComponent as PuzzleIcon } from "src/assets/Puzzle.svg";
import ActionButtonWithConfirmDialog from "src/components/Button/ActionButtonWithConfirmDialog";
import ModalContainer from "src/components/ModalContainer";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import formatDateString from "src/utils/formatDateString";
import getMilestoneCompletionInstructions from "src/utils/getMilestoneCompletionInstructions";
import DocumentProofSection, {
  DocumentProofForm,
} from "./DocumentProofSection";

interface OverdueMilestoneModalProps {
  overdueMilestone?: StudentPlanMilestone;
  onComplete: (proofFiles?: DocumentProofForm) => void;
  onReschedule: () => void;
}

export default function OverdueMilestoneCheckInModal({
  overdueMilestone,
  onComplete,
  onReschedule,
}: OverdueMilestoneModalProps) {
  const { t } = useTranslation();
  const [showConfetti, setShowConfetti] = useState(false); // State to control confetti visibility
  const [showDocumentForm, setShowDocumentForm] = useState(false);

  const handleComplete = (data?: DocumentProofForm) => {
    setShowConfetti(true); // Show confetti when milestone is marked complete
    setTimeout(() => {
      setShowConfetti(false); // Hide confetti after 3 seconds
      onComplete(data);
    }, 3000);
  };

  // This is to prevent some legacy milestones from disrupting the participant's experience
  const CUTOFF_DATE = new Date("2024-12-1");

  if (
    !overdueMilestone ||
    isBefore(new Date(overdueMilestone.date), CUTOFF_DATE)
  ) {
    return <div />;
  }

  const completionInstructions = getMilestoneCompletionInstructions(
    overdueMilestone.type
  );

  if (showConfetti) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "none", // Ensure confetti doesn't block interactions
          overflow: "hidden", // Prevent scrolling due to large animation
        }}
      >
        {/* Lottie animation with a larger size */}
        <Lottie
          animationData={Confetti}
          autoplay
          loop={false} // Confetti doesn't need to loop
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      </Box>
    );
  }
  return (
    <Modal open={!!overdueMilestone}>
      <ModalContainer>
        {showConfetti && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              zIndex: 9999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pointerEvents: "none", // Ensure confetti doesn't block interactions
              overflow: "hidden", // Prevent scrolling due to large animation
            }}
          >
            {/* Lottie animation with a larger size */}
            <Lottie
              animationData={Confetti}
              autoplay
              loop={false} // Confetti doesn't need to loop
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </Box>
        )}
        <div>
          {overdueMilestone && (
            <Card>
              <CardHeader
                avatar={<PuzzleIcon width={100} />}
                title="Milestone Check-In"
                subheader={overdueMilestone.title}
              />
              <CardContent>
                <Typography gutterBottom>
                  <Trans
                    t={t}
                    defaults="Your goal was to complete <bold>{{ name }}</bold> by <bold>{{ date }}</bold>. How did it go? "
                    components={{ bold: <b /> }}
                    tOptions={{ interpolation: { escapeValue: false } }}
                    values={{
                      date: formatDateString(overdueMilestone.date, "date"),
                      name: `${overdueMilestone.title}`,
                    }}
                  />
                </Typography>

                <Collapse in={showDocumentForm} timeout="auto" unmountOnExit>
                  {completionInstructions && (
                    <Box marginTop={2}>
                      <DocumentProofSection
                        completionInstructions={completionInstructions}
                        onSubmit={(data) => handleComplete(data)}
                      />
                    </Box>
                  )}
                </Collapse>
              </CardContent>

              <CardActions>
                <Box
                  width="100%"
                  sx={{
                    display: "flex",
                    margin: { xs: 4, sm: 0 }, // Add margin on small screens and up
                    flexDirection: {
                      xs: "column", // Stack vertically on extra small screens
                      sm: "row", // Display side by side on small screens and up
                    },
                    justifyContent: {
                      xs: "center", // Center align vertically stacked buttons
                      sm: "space-between", // Space between on small screens and up
                    },
                    gap: { xs: 2, sm: 0 }, // Add gap between buttons on small screens and up
                  }}
                >
                  {/* If doc proof is required, user toggles the new form */}
                  {completionInstructions?.isDocumentProofRequired ||
                  completionInstructions?.canBeReimbursed ? (
                    showDocumentForm ? (
                      <Button
                        onClick={() => setShowDocumentForm(!showDocumentForm)}
                      >
                        {t("Cancel")}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => setShowDocumentForm(!showDocumentForm)}
                        variant="contained"
                        color="success"
                        startIcon={<Rocket />}
                      >
                        {t("Mark as Complete")}
                      </Button>
                    )
                  ) : (
                    <ActionButtonWithConfirmDialog
                      onAction={handleComplete}
                      buttonColor="success"
                      buttonVariant="contained"
                      buttonLabel={t("Mark as complete")}
                      startIcon={<Rocket />}
                      title="Milestone Achieved?"
                      description="Once you confirm, we’ll notify your coach and support network that you’ve completed this milestone so they can celebrate with you! 🎉"
                    />
                  )}
                  <Button
                    onClick={() => {
                      onReschedule();
                    }}
                    variant="outlined"
                    startIcon={<CalendarMonth />}
                  >
                    {t("Reschedule for later")}
                  </Button>
                </Box>
              </CardActions>
            </Card>
          )}
        </div>
      </ModalContainer>
    </Modal>
  );
}
