import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "src/SessionBoundary";
import { getFullName } from "src/utils";

type Props = {
  title: string;
  expectations: {
    title: string;
    description: string;
  }[];
  onBack?: () => void;
  onContinue?: () => void;
};
const CodeOfConductCard = ({
  title,
  expectations,
  onBack,
  onContinue,
}: Props) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const user = useCurrentUser();

  return (
    <Card sx={{ marginTop: 1 }} variant="outlined">
      <CardMedia
        image="https://f004.backblazeb2.com/file/emerge-courses/assets/A+Calm%2C+Hyper-Realistic+Scene+of+Truck+Outside+High-Security+Prison.png"
        alt="truck outside high-security prison"
        component="img"
        height={200}
        sx={{
          objectFit: "cover",
          objectPosition: "center",
          display: { xs: "none", md: "block" },
        }}
      />
      <CardHeader title={title} />
      <CardContent>
        <List>
          {expectations.map((expectation) => (
            <ListItem key={expectation.title}>
              <ListItemText>
                <Typography variant="subtitle2">{expectation.title}</Typography>
                <Typography variant="body2">
                  {expectation.description}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
          }
          label={t("I, {{name}}, understand and agree to the expectations", {
            name: getFullName(user),
          })}
          sx={{ marginTop: 1 }}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          marginTop={2}
          alignItems="center"
        >
          {onBack && (
            <Button variant="outlined" onClick={onBack}>
              {t("Back")}
            </Button>
          )}
          {onContinue && (
            <Button
              variant="contained"
              onClick={onContinue}
              disabled={!checked}
            >
              {t("Continue")}
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CodeOfConductCard;
