import { Timestamp } from "firebase/firestore";

// Define a generic type that ensures both keyName and keyNameFirestoreTimestamp are present
type SerializedDate<Key extends string> = {
  [K in Key]: string;
} & {
  [K in `${Key}FirestoreTimestamp`]: Timestamp;
};

/**
 * Generates a serialized object containing the ISO string of the date and its Firestore Timestamp.
 *
 * @param date - The date to serialize. If undefined, an empty object is returned.
 * @param keyName - The base key name for the serialized date and its Firestore Timestamp.
 * @returns An object containing both the ISO string and Firestore Timestamp with strongly-typed keys.
 */
export default function generateFirestoreTimestamps<Key extends string>(
  date: Date | undefined,
  keyName: Key
): SerializedDate<Key> {
  if (!date) {
    // Return an empty object with the appropriate type
    return {} as SerializedDate<Key>;
  }

  const dateString = date.toISOString();
  const firestoreTimestamp: Timestamp = Timestamp.fromDate(date);

  return {
    [keyName]: dateString,
    [`${keyName}FirestoreTimestamp`]: firestoreTimestamp,
  } as SerializedDate<Key>;
}
