import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Header20 } from "src/components/Typography";
import { useCurrentUser } from "src/SessionBoundary";

/**
 * We store each weekday as an integer:
 * 1 = Monday, 2 = Tuesday, 3 = Wednesday, 4 = Thursday,
 * 5 = Friday, 6 = Saturday, 0 = Sunday
 */
export const WEEKDAY_MAP: Record<number, string> = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

interface DrivingScheduleSelectionProps {
  /**
   * Pre-selected weekdays, if any (stored as integers).
   */
  defaultSelected?: number[];
  /**
   * Callback when the user hits "Save".
   * Receives the integer list of selected weekdays.
   */
  onSave?: (selectedWeekdays: number[]) => void;
  onBack: () => void;
}

export default function DrivingScheduleSelection({
  defaultSelected = [],
  onSave,
  onBack,
}: DrivingScheduleSelectionProps) {
  const { t } = useTranslation();

  // Local state to track which days are selected (by integer).
  const [selectedDays, setSelectedDays] = useState<number[]>(defaultSelected);
  const existingSchedule =
    useCurrentUser().skillsTrainingPlan?.skillsTrainingSchedule;

  // Toggle a day in or out of the selection
  const toggleDay = (dayInt: number) => {
    setSelectedDays(
      (prev) =>
        prev.includes(dayInt)
          ? prev.filter((d) => d !== dayInt) // remove if already included
          : [...prev, dayInt] // add if not selected
    );
  };

  // Handle saving the selection
  const handleSave = () => {
    onSave?.(selectedDays);
  };

  return (
    <Stack spacing={2}>
      {/* Alert reminding the user of the schedule they chose */}

      <Header20>{t("Which weekdays are you available to train?")}</Header20>

      <Typography>
        {t(
          "Select ALL the days you're available. We’ll coordinate with the school to find the best match."
        )}
      </Typography>

      <Alert severity="info" sx={{ mb: 2 }}>
        <AlertTitle>{t("Remember Your Schedule")}</AlertTitle>
        {t(
          "You've already chosen: {{ schedule }}. Make sure the days you select align with this commitment.",
          { schedule: existingSchedule }
        )}
      </Alert>

      <Card variant="outlined">
        <CardContent>
          <FormGroup>
            {Object.entries(WEEKDAY_MAP).map(([dayInt, dayName]) => {
              const dayNumber = parseInt(dayInt, 10);
              return (
                <FormControlLabel
                  key={dayInt}
                  label={t(dayName)} // wrap in translation
                  control={
                    <Checkbox
                      checked={selectedDays.includes(dayNumber)}
                      onChange={() => toggleDay(dayNumber)}
                    />
                  }
                />
              );
            })}
          </FormGroup>
        </CardContent>
      </Card>
      <Box marginTop={2}>
        <Button onClick={onBack}>{t("Back")}</Button>
        <Button variant="contained" onClick={handleSave}>
          {t("Save Selection")}
        </Button>
      </Box>
    </Stack>
  );
}
