import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Header20 } from "src/components/Typography";
import { useCurrentUser } from "src/SessionBoundary";

export default function CreateBackgroundCheckCard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useCurrentUser();

  //  if the user has a background check report, return an empty div
  if (user.backgroundCheckReport?.reportId) return <div />;
  if (user.backgroundCheckReport?.report) return <div />;
  // if the user has completed their invitation request, return an empty div
  if (
    user.backgroundCheckReport?.invitation?.status === "completed" ||
    user.backgroundCheckReport?.invitation?.status === "cancelled" ||
    user.backgroundCheckReport?.invitation?.status === "expired"
  )
    return <div />;

  if (user.backgroundCheckReport?.invitationUrl) {
    return (
      <Card>
        <CardContent>
          <Chip
            variant="outlined"
            color="info"
            label={t("Milestone Completion")}
          />
          <Header20 marginTop={1}>{t("Finish Your Background Check")}</Header20>
          <Typography marginTop={1}>
            {t(
              "We created your background check invitation. Click here now to finish the process!"
            )}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            onClick={() =>
              window.open(user.backgroundCheckReport!.invitationUrl)
            }
          >
            {t("Finish")}
          </Button>
        </CardActions>
      </Card>
    );
  }

  return (
    <Card>
      <CardActionArea onClick={() => navigate("/eligibility")}>
        <CardContent>
          <Chip variant="outlined" label={t("Task")} color="info" />
          <Header20 marginTop={1}>{t("Start Your Background Check")}</Header20>
          <Typography marginTop={1}>
            {t(
              "We run a background check to confirm the information provided during the application."
            )}
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained">{t("Start")}</Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
