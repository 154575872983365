import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/contexts/AppContext";
import fetchSchool from "src/firebase/fetchSchool";
import SchoolCard from "src/pages/OnboardingScreen/SchoolCard";
import { School } from "src/types/School";
import { UserAccount } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";

type Props = {
  user: UserAccount;
};

export default function SkillsTrainingPlanCard({ user }: Props) {
  const { t } = useTranslation();
  const { skillsTrainingPlan } = user;
  const [school, setSchool] = useState<School>();
  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();

  useEffect(() => {
    if (user.schoolId) {
      fetchSchool(user.schoolId, clients).then(setSchool).catch(errorHandler);
    }
  }, [user.schoolId, errorHandler, clients]);

  if (!skillsTrainingPlan)
    return (
      <Card>
        <CardContent>
          <CardHeader
            title={t("Skills Training Plan")}
            subheader={t("Incomplete")}
          />
        </CardContent>
      </Card>
    );

  return (
    <Box>
      {school ? (
        <SchoolCard school={school} variant="outlined" />
      ) : (
        <Skeleton variant="rectangular" width={100} />
      )}
      <Alert severity="info" sx={{ marginTop: 2 }}>
        <Typography variant="body2">
          {t("Current training schedule:")}
        </Typography>
        <Typography fontWeight="bold">
          {skillsTrainingPlan.skillsTrainingSchedule}
        </Typography>
      </Alert>
    </Box>
  );
}
