import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LocationCard from "src/components/Card/LocationCard";
import { useAppContext } from "src/contexts/AppContext";
import fetchSchool from "src/firebase/fetchSchool";
import AppSkeleton from "src/pages/AppSkeleton";
import { useCurrentUser } from "src/SessionBoundary";
import { School } from "src/types/School";
import getDistanceBetweenZips from "src/utils/getDistanceBetweenZips";
import useErrorHandler from "src/utils/useErrorHandler";
import { WEEKDAY_MAP } from "./DrivingScheduleSelection";

const LazyMapWithMarkers = lazy(() => import("src/components/MapWithMarkers"));

export type StipendInformation = {
  startPointZipcode: string;
  distanceMiles: number;
  dailyStipendEstimateCents: number;
};

type Props = {
  trainingDays: number[];
  onComplete: (stipendInfo: StipendInformation) => void;
};

export default function StipendSetupForm({ trainingDays, onComplete }: Props) {
  const { t } = useTranslation();

  const [coords, setCoords] =
    useState<{ lat: number; lon: number; label: string }[]>();
  const [distanceMiles, setdistanceMiles] = useState<number | null>(null);
  const [dailyStipend, setDailyStipend] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [school, setSchool] = useState<School>();
  const user = useCurrentUser();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  const startingPointZipcode =
    user.eligibilityData?.zipcode || user.application?.zipcode;

  if (!startingPointZipcode)
    throw new Error("Please contact your coach to provide your zipcode.");
  if (!user.schoolId)
    throw new Error("You must pick a school before you move forward.");

  if (!user.schoolId)
    throw new Error("You must pick a school before you move forward.");

  useEffect(() => {
    fetchSchool(user.schoolId!, clients).then(setSchool).catch(errorHandler);
  }, [user.schoolId, clients, errorHandler]);

  const AVERAGE_GALLON_PRICE = 3.31;
  const AVERAGE_MILES_PER_GALLON = 24.4;

  const handleCalculate = useCallback(async () => {
    if (!school) return;
    setLoading(true);
    try {
      // Example function that returns distance in kilometers
      const { distanceInMiles, startCoords, endCoords } =
        await getDistanceBetweenZips(startingPointZipcode, school.zipcode);

      setdistanceMiles(distanceInMiles);
      setCoords([
        { lat: startCoords.lat, lon: startCoords.lon, label: t("Your Home") },
        { lat: endCoords.lat, lon: endCoords.lon, label: school.name },
      ]);

      const dailyStipend =
        ((distanceInMiles * 2) / AVERAGE_MILES_PER_GALLON) *
        AVERAGE_GALLON_PRICE;

      setDailyStipend(dailyStipend);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [school, startingPointZipcode, errorHandler, t]);

  useEffect(() => {
    handleCalculate();
  }, [handleCalculate]);

  const handleSubmit = () => {
    if (distanceMiles !== null) {
      onComplete({
        startPointZipcode: startingPointZipcode,
        distanceMiles,
        dailyStipendEstimateCents: dailyStipend,
      });
    }
  };

  if (loading || !school) return <AppSkeleton />;

  return (
    <Box>
      <Card>
        <CardMedia>
          {coords && (
            <Suspense fallback={<div>Loading map...</div>}>
              <LazyMapWithMarkers locations={coords} />
            </Suspense>
          )}
        </CardMedia>
        <CardContent>
          <Box marginY={2}>
            <Typography variant="body2" color="textSecondary">
              {t("Your Training Days:")}
            </Typography>
            <Typography>
              {trainingDays.map((d) => WEEKDAY_MAP[d]).join(", ")}
            </Typography>
          </Box>

          <LocationCard
            variant="outlined"
            startLocation={startingPointZipcode}
            destination={school.name}
            destinationLabel={t("Your School")}
            distance={distanceMiles || 0}
            dailyStipend={dailyStipend}
            trainingDays={trainingDays}
          />
          <Alert color="info" sx={{ marginTop: 2 }}>
            <AlertTitle>
              {t("Get Reimbursed—Don’t Forget to Log Your Attendance!")}
            </AlertTitle>
            {t(
              "To receive your reimbursement, you must log your attendance daily in the app."
            )}
          </Alert>
        </CardContent>
      </Card>

      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          disabled={distanceMiles === null}
          onClick={handleSubmit}
        >
          {t("Sounds Good")}
        </Button>
      </Box>
    </Box>
  );
}
