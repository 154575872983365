/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScreenTitle } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import fetchBackground from "src/firebase/fetchBackgroundProfile";
import updateUserEligibilityData from "src/firebase/updateUserEligibilityData";
import { SEED_ELIGIBLITY_FORM_DATA } from "src/seed";
import { useCurrentUser } from "src/SessionBoundary";
import { getFullName } from "src/utils";
import transformDateFields from "src/utils/transformDateFields";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../AppSkeleton";
import DriverLicenseForm, {
  DriverLicenseData,
} from "../BackgroundSurveyScreen/DriverLicenseForm";
import SuccessScreenModal from "../SucessScreenModal";
import { trackEvent } from "./amplitude";
import EligibilityPersonalIdentificationForm, {
  EligibilityPersonalIdentificationData,
} from "./EligibilityScreen/EligibilityPersonalIdentificationData";
import EligibilityPostalAddressForm, {
  PostalAddress,
} from "./EligibilityScreen/EligibilityPostalAddressForm";
import Signature from "./Signature.svg";

type FormStep =
  | "personal_identification"
  | "postal_address"
  | "driver_license"
  | "income";

const stepLabels: Record<FormStep, string> = {
  personal_identification: "Your Legal Information",
  driver_license: "Driver's License",
  postal_address: "Postal Address",
  income: "Income Verification",
};

const stepNames: FormStep[] = [
  "personal_identification",
  "postal_address",
  "driver_license",
];

export type EligibilityFormData = DriverLicenseData &
  EligibilityPersonalIdentificationData & { postalAddress: PostalAddress };

const getStepIndex = (stepName: FormStep): number =>
  stepNames.indexOf(stepName);

export default function EligibilityScreen() {
  const { t } = useTranslation();
  const [step, setStep] = useState<FormStep>("personal_identification");
  const [finished, setFinished] = useState(false);
  const navigate = useNavigate();
  const user = useCurrentUser();
  const errorHandler = useErrorHandler();
  const [eligibilityData, setEligibilityData] =
    useState<Partial<EligibilityFormData>>();
  const { clients } = useAppContext();

  // pre-populate data with existing data
  useEffect(() => {
    fetchBackground(user.uid, clients)
      .then((background) => {
        if (background) {
          const transformed = transformDateFields(background);
          setEligibilityData(
            process.env.NODE_ENV === "development"
              ? SEED_ELIGIBLITY_FORM_DATA
              : {
                  legalFirstName: user.firstName,
                  legalLastName: user.lastName,
                  legalMiddleName: "",
                  zipcode: transformed.zipcode,
                  // TODO: this is needed for now because of the legacy data type of the form
                  // Around April 2025, we should be able to remove this
                  ssn: (transformed as any).ssn,
                  driverLicenseNumber: (transformed as any).driverLicenseNumber,
                  driverLicenseState: (transformed as any).driverLicenseState,
                  driverLicenseIssueDate: (transformed as any)
                    .driverLicenseIssueDate,
                  driverLicenseExpirationDate: (transformed as any)
                    .driverLicenseExpirationDate,
                  postalAddress: {
                    street: "",
                    name: getFullName(user),
                    city: user.city,
                    state: user.state,
                    zipcode: transformed.zipcode || "",
                  },
                }
          );
        } else {
          setEligibilityData({});
        }
      })
      .catch(errorHandler);
  }, [clients, errorHandler, user]);

  const onSubmit = async (input: EligibilityFormData) => {
    await updateUserEligibilityData({ user, ...input }, clients)
      .then(() => setFinished(true))
      .catch(errorHandler);
  };

  const handleNavigation = () => {
    navigate("/");
  };

  if (!eligibilityData) return <AppSkeleton />;

  if (!finished) {
    return (
      <>
        <ScreenTitle>{stepLabels[step]}</ScreenTitle>

        <Box width="100%" marginTop={2}>
          <LinearProgress
            value={(getStepIndex(step) * 100) / stepNames.length}
            variant="determinate"
          />
          <Stack direction="row">
            <Typography variant="body2" color="text.secondary">
              {`${Math.round((getStepIndex(step) * 100) / stepNames.length)}%`}
            </Typography>
          </Stack>
        </Box>
        {step === "personal_identification" && (
          <>
            <Box marginY={2}>
              <Typography variant="body2">
                {t(
                  "To formalize your scholarship offer, we need to confirm your legal information and your social security number to run a final background check."
                )}
              </Typography>
            </Box>

            <EligibilityPersonalIdentificationForm
              defaultValues={eligibilityData}
              onSubmit={(data) => {
                trackEvent("Eligibility Screen - SSN Submitted");
                setEligibilityData((prev) => ({ ...prev, ...data }));
                setStep("postal_address");
              }}
            />
          </>
        )}

        {step === "postal_address" && (
          <>
            <Box marginY={2}>
              <Typography variant="body2">
                {t(
                  "We need your address for important documents—and maybe some Emerge swag after you graduate!"
                )}
              </Typography>
            </Box>
            <EligibilityPostalAddressForm
              defaultValues={eligibilityData.postalAddress}
              onSubmit={(data) => {
                trackEvent("Eligibility Screen - Postal Address Submitted");
                setEligibilityData({ ...eligibilityData, postalAddress: data });
                setStep("driver_license");
              }}
              onBack={() => setStep("personal_identification")}
            />
          </>
        )}

        {step === "driver_license" && (
          <>
            <Box marginY={2}>
              <Typography variant="body2">
                {t(
                  "Let's now confirm your active driver's license information!"
                )}
              </Typography>
            </Box>
            <DriverLicenseForm
              defaultValues={eligibilityData}
              onBack={() => setStep("postal_address")}
              onSubmit={(data) => {
                trackEvent("Eligibility Screen - Driver License Submitted");
                setEligibilityData({ ...eligibilityData, ...data });
                onSubmit({
                  ...eligibilityData,
                  ...data,
                } as EligibilityFormData);
              }}
            />
          </>
        )}
      </>
    );
  }

  return (
    <SuccessScreenModal
      open={finished}
      illustration={Signature}
      illustrationWidth={200}
      title={t("Check your email!")}
      content={t(
        "We now have all the information we need! You will receive an email from us and from our partner, Checkr, soon to complete your background check."
      )}
      okButtonText={t(`Let's keep going`)}
      onOkButtonClick={handleNavigation}
    />
  );
}
