import { HelpOutlineOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState } from "react";
import SliderExplanationScreen, {
  SliderExplanationStepProp,
} from "src/pages/SliderExplanationScreen";

type ButtonWithDialogExplanationProps = {
  title: string;
  steps: SliderExplanationStepProp[];
};

export default function ButtonWithDialogExplanation({
  title,
  steps,
}: ButtonWithDialogExplanationProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        startIcon={<HelpOutlineOutlined fontSize="small" />}
        variant="text"
        color="primary"
        onClick={() => setOpen(true)}
      >
        {title}
      </Button>
      {open && (
        <SliderExplanationScreen
          steps={steps}
          finishButtonProps={{ onFinish: () => setOpen(false) }}
        />
      )}
    </>
  );
}
