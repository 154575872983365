import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Empty from "src/components/Empty";
import { useAppContext } from "src/contexts/AppContext";
import createChatMessage from "src/firebase/createChatMessage";
import fetchOrganizationSchools from "src/firebase/fetchOrganizationSchools";
import updateUserSchool from "src/firebase/updateUserSchool";
import updateUserSkillsTrainingPlan from "src/firebase/updateUserSkillsTrainingPlan";
import { useCurrentUser } from "src/SessionBoundary";
import { School } from "src/types/School";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../AppSkeleton";
import { ChatMessageSenderType } from "../DashboardPage/AdminParticipantChatTab/ChatMessage";
import ConfirmSchoolSelection from "./ConfirmSchoolSelection";
import SchoolCard from "./SchoolCard";

type Props = { onSuccess: (selectedSchool: School) => void };
export default function SchoolSelectionForm({ onSuccess }: Props) {
  const { clients } = useAppContext();
  const user = useCurrentUser();
  const [schools, setSchools] = useState<School[]>([]);
  const [filteredSchools, setFilteredSchools] = useState<School[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedSchedule, setSelectedSchedule] = useState<string | null>(null);
  const [selectedSchoolId, setSelectedSchoolId] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState<
    "filter" | "select" | "review"
  >("filter");
  const errorHandler = useErrorHandler();
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!user.organizationId) {
    throw new Error(
      `You must be part of an active training cohort to select a school.`
    );
  }

  useEffect(() => {
    fetchOrganizationSchools(user.organizationId!, clients)
      .then(setSchools)
      .catch(errorHandler)
      .finally(() => setLoading(false));
  }, [user.organizationId, clients, errorHandler]);

  const handleScheduleFilter = () => {
    if (selectedSchedule === "both" || selectedSchedule === "full-time") {
      setFilteredSchools(schools);
    } else if (selectedSchedule === "part-time") {
      setFilteredSchools(
        schools.filter((school) => school.hasPartTimeSchedule)
      );
    }
    setCurrentStep("select");
  };

  const handleSelectSchool = (schoolId: string) => {
    setSelectedSchoolId(schoolId);
  };

  const handleConfirmSelection = () => {
    setCurrentStep("review");
  };

  const handleConfirmReview = async (trainingSchedule: string) => {
    if (!selectedSchoolId) throw new Error("Invalid selection.");

    const selectedSchool = filteredSchools.find(
      (school) => school.uid === selectedSchoolId
    );

    if (!selectedSchool) throw new Error("Invalid selection.");

    await updateUserSchool(
      {
        user,
        school: selectedSchool,
      },
      clients
    )
      .then(() => onSuccess(selectedSchool))
      .catch(errorHandler);

    await updateUserSkillsTrainingPlan(
      {
        user,
        skillsTrainingPlan: {
          skillsTrainingSchedule: trainingSchedule,
          projectedDurationWeeks: selectedSchedule === "full-time" ? 4 : 8,
        },
      },
      clients
    ).catch(errorHandler);
  };

  const selectedSchool = filteredSchools.find(
    (school) => school.uid === selectedSchoolId
  );

  const handleNotifyTeam = async () => {
    try {
      await createChatMessage(
        {
          participantId: user.uid,
          senderId: user.uid,
          senderType: ChatMessageSenderType.Participant,
          text: `There are no schools available to me. I live in ${user.city} and I am looking for a ${selectedSchedule} schedule.`,
        },
        clients
      );
      navigate("/coach");
    } catch (error) {
      errorHandler(error);
    }
  };

  if (loading) return <AppSkeleton />;

  return (
    <FormControl component="fieldset">
      {currentStep === "filter" && (
        <Box>
          <Typography variant="h6" gutterBottom>
            {t("Please select your availability:")}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {t(
              "Note: Even for part-time schedules, we require at least 20 hours a week of availability to train."
            )}
          </Typography>
          <RadioGroup
            value={selectedSchedule}
            onChange={(e) => setSelectedSchedule(e.target.value)}
          >
            <FormControlLabel
              value="full-time"
              control={<Radio />}
              label="Full-time"
            />
            <FormControlLabel
              value="part-time"
              control={<Radio />}
              label="Part-time"
            />
            <FormControlLabel value="both" control={<Radio />} label="Both" />
          </RadioGroup>
          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleScheduleFilter}
              disabled={!selectedSchedule}
            >
              {t("Confirm Availability")}
            </Button>
          </Box>
        </Box>
      )}

      {currentStep === "select" && (
        <Box>
          {filteredSchools.length > 0 ? (
            <Grid
              container
              spacing={2}
              maxHeight={500}
              sx={{ overflowY: "auto" }}
            >
              {filteredSchools.map((school) => (
                <Grid item xs={12} key={school.uid}>
                  <SchoolCard
                    variant="outlined"
                    key={school.uid}
                    school={school}
                    onSelect={() => handleSelectSchool(school.uid)}
                    selected={selectedSchoolId === school.uid}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Empty
              description="Looks like no schools match your schedule right now, but don’t worry! We can alert you when new options open up."
              cta={{
                label: "Notify the Team",
                onClick: handleNotifyTeam,
              }}
              secondaryCta={{
                label: "Change Availability",
                onClick: () => setCurrentStep("filter"),
              }}
            />
          )}
          {filteredSchools.length > 0 && (
            <Box mt={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmSelection}
                disabled={!selectedSchoolId}
                size="large"
              >
                {t("Confirm my school")}
              </Button>
            </Box>
          )}
        </Box>
      )}

      {currentStep === "review" && selectedSchool && (
        <Box>
          <ConfirmSchoolSelection
            selectedSchool={selectedSchool}
            onConfirm={handleConfirmReview}
            onBackButton={() => setCurrentStep("select")}
          />
        </Box>
      )}
    </FormControl>
  );
}
