/* eslint-disable arrow-body-style */
import { useLazyQuery } from "@apollo/client";
import {
  Box,
  capitalize,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { QuizRecord } from "src/clients/schema";
import AppSkeleton from "src/pages/AppSkeleton";
import { GetQuizDocument } from "src/pages/QuizPage/GetQuiz.generated";
import QuizView from "src/pages/QuizPage/QuizView";
import { useCurrentUser } from "src/SessionBoundary";
import hasPassedQuiz from "src/utils/hasPassedQuiz";
import useCompleteQuizGrades from "src/utils/useCompleteQuizGrades";
import useErrorHandler from "src/utils/useErrorHandler";
import Button from "../Button";

interface Props {
  quiz: Pick<QuizRecord, "id" | "name" | "quizType">;
  locked?: boolean;
  // needs to be optional because of the legacy pages for the previous UX navigation
  onFinishQuiz?: () => Promise<void>;
}

const QuizCard = ({ quiz, locked, onFinishQuiz }: Props) => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const { findGradeByQuizId } = useCompleteQuizGrades(user.uid);
  const grade = findGradeByQuizId(quiz.id);
  const theme = useTheme();
  const errorHandler = useErrorHandler();
  const [getQuiz, { data: quizData, loading: quizLoading }] = useLazyQuery(
    GetQuizDocument,
    {
      onError: errorHandler,
    }
  );

  // Local state to control the dialog
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleOpen = async () => {
    setOpen(true);
    await getQuiz({ variables: { quizId: quiz.id } });
  };

  const handleFinishQuiz = async () => {
    handleClose();
    if (onFinishQuiz) {
      try {
        await onFinishQuiz();
      } catch (err) {
        errorHandler(err);
      }
    }
  };

  return (
    <>
      <Card sx={{ backgroundColor: theme.palette.grey[200] }}>
        <CardContent>
          <Stack spacing={2}>
            <Box>
              <Typography variant="body2">
                {capitalize(quiz.quizType)}: {quiz.name}
              </Typography>
              {grade && (
                <Stack direction="row" spacing={1}>
                  {hasPassedQuiz(grade) ? (
                    <Typography variant="body2" color="green">
                      {t("Passed")}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="red">
                      {t("Failed")}
                    </Typography>
                  )}
                  <Typography variant="body2">
                    {grade.totalCorrectAnswers} / {grade.totalQuestions} (
                    {grade.grade * 100}%)
                  </Typography>
                </Stack>
              )}
            </Box>

            {grade ? (
              // need to allow locked in case its a legacy user who started jumping around video lessons
              <Button variant="outlined" onClick={handleOpen} disabled={locked}>
                {t("Re-Take")}
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled={locked}
                onClick={handleOpen}
              >
                {t("Take the Video Quiz")}
              </Button>
            )}
          </Stack>
        </CardContent>
      </Card>

      {/* Dialog that surfaces the quiz */}
      <Dialog open={open} fullScreen={isMobile}>
        <DialogContent>
          {quizLoading && <AppSkeleton />}
          {quizData?.quiz && (
            <QuizView quiz={quizData.quiz} onFinish={handleFinishQuiz} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QuizCard;
