import { AddAPhoto, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalContainer from "src/components/ModalContainer";
import { useAppContext } from "src/contexts/AppContext";
import { useSession } from "src/contexts/AuthContext";
import fetchStorageBlob from "src/firebase/fetchStorageBlob";
import AppSkeleton from "src/pages/AppSkeleton";
import { useSnackbarContext } from "src/SnackbarProvider";
import { Attendance } from "src/types/Attendance";
import formatOptionalDateString from "src/utils/formatOptionalDateString";
import sortBy from "src/utils/sortBy";
import useErrorHandler from "src/utils/useErrorHandler";
import useIsAdmin from "src/utils/useIsAdmin";
import RangeAttendanceForm, { AttendanceFormData } from "./RangeAttendanceForm";

type Props = { client?: { uid: string } };

export default function RangeTab({ client }: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const session = useSession();
  const errorHandler = useErrorHandler();
  const snackbarContext = useSnackbarContext();
  const [attendance, setAttendance] =
    useState<(Attendance & { file?: File })[]>();
  const theme = useTheme();
  const isAdmin = useIsAdmin();
  const { clients } = useAppContext();

  useEffect(() => {
    session
      .fetchAttendanceRecords(client?.uid)
      .then(async (records) => {
        const processed = await Promise.all(
          records.map(async (r) => ({
            ...r,
            file: r.path
              ? await fetchStorageBlob({ path: r.path }, clients)
              : undefined,
          }))
        );

        setAttendance(processed);
      })
      .catch(errorHandler);
  }, [session, errorHandler, client, clients]);

  const handleSubmission = useCallback(
    async (data: AttendanceFormData) => {
      await session
        .createAttendance(data, client?.uid)
        .then((res) => {
          snackbarContext.alert(
            "success",
            t("Successfully submitted your attendance")
          );
          setAttendance((prev) => [
            { ...res, file: data.photo || undefined },
            ...(prev || []),
          ]);
        })
        .catch(errorHandler);

      setIsOpen(false);
    },
    [t, snackbarContext, errorHandler, session, client]
  );

  const handleDeletion = async (attendanceId: string) => {
    snackbarContext.alert("info", "Deleting record...");
    if (!client) throw new Error("Only admins can delete an attendance record");
    await session.deleteAttendance(client.uid, attendanceId);
    setAttendance((prev) => prev?.filter((att) => att.uid !== attendanceId));
  };

  if (!attendance) return <AppSkeleton />;

  return (
    <Stack spacing={theme.spacing(2)}>
      <Box sx={{ marginLeft: "auto" }}>
        <Button
          variant="outlined"
          onClick={() => setIsOpen(true)}
          startIcon={<AddAPhoto />}
        >
          {t("Add")}
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableCell>{t("Date")}</TableCell>

          <TableCell />
        </TableHead>
        <TableBody>
          {sortBy(
            attendance,
            (record) => new Date(record.createdAt).getTime(),
            "desc"
          ).map((record) => (
            <TableRow id={record.uid}>
              <TableCell>
                <Typography>
                  {formatOptionalDateString("dateyear", record.date)}{" "}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {record.duration
                    ? t(`${record.duration} hours of training`)
                    : ""}
                </Typography>
              </TableCell>
              <TableCell>
                {record.file && (
                  <img
                    src={URL.createObjectURL(record.file)}
                    alt={record.path}
                    width={250}
                  />
                )}
              </TableCell>
              <TableCell>
                {isAdmin && (
                  <Button
                    startIcon={<Delete />}
                    onClick={() => handleDeletion(record.uid)}
                  >
                    {t("Delete")}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <div>
          <ModalContainer>
            <Card>
              <CardHeader title={t("Attendance Form")} />
              <CardContent>
                <RangeAttendanceForm handleSubmission={handleSubmission} />
              </CardContent>
            </Card>
          </ModalContainer>
        </div>
      </Modal>
    </Stack>
  );
}
