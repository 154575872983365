import {
  AccessTime,
  CalendarMonth,
  Delete,
  Login,
  MonetizationOn,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  addDays,
  getDay,
  isAfter,
  isBefore,
  isEqual,
  startOfWeek,
} from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/contexts/AppContext";
import { useSession } from "src/contexts/AuthContext";
import createAttendance from "src/firebase/createAttendance";
import fetchStorageBlob from "src/firebase/fetchStorageBlob";
import AppSkeleton from "src/pages/AppSkeleton";
import { useSnackbarContext } from "src/SnackbarProvider";
import { Attendance } from "src/types/Attendance";
import { UserAccount } from "src/types/User";
import formatOptionalTimestamp from "src/utils/formatOptionalTimestamp";
import isFullSkillsTrainingPlanGuard from "src/utils/isFullSkillsTrainingPlanGuard";
import sortBy from "src/utils/sortBy";
import useErrorHandler from "src/utils/useErrorHandler";
import useIsAdmin from "src/utils/useIsAdmin";
import { WEEKDAY_MAP } from "./DrivingScheduleSelection";
import RangeAttendanceForm, { AttendanceFormData } from "./RangeAttendanceForm";

type Props = {
  user: UserAccount;
  /**
   * An array of days of the week (0 = Sunday, 1 = Monday, etc.)
   * that are considered "training days."
   */
};

export default function SkillsTrainingDashboard({ user }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const session = useSession();
  const errorHandler = useErrorHandler();
  const snackbarContext = useSnackbarContext();
  const isAdmin = useIsAdmin();
  const { clients } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [attendancesWithPhoto, setAttendanceWithPhoto] =
    useState<(Attendance & { file?: File })[]>();

  // --- FETCH ATTENDANCE ON MOUNT ---
  useEffect(() => {
    session
      .fetchAttendanceRecords(user.uid)
      .then(async (records) => {
        const processed = await Promise.all(
          records.map(async (r) => ({
            ...r,
            file: r.path
              ? await fetchStorageBlob({ path: r.path }, clients)
              : undefined,
          }))
        );
        setAttendanceWithPhoto(processed);
      })
      .catch(errorHandler);
  }, [session, errorHandler, user, clients]);

  // --- HANDLER: SUBMIT ATTENDANCE ---
  const handleSubmission = useCallback(
    async (data: AttendanceFormData) => {
      await createAttendance({ user, ...data }, clients)
        .then((res) => {
          setIsOpen(false);
          setAttendanceWithPhoto((prev) => [
            { ...res, file: data.photo || undefined },
            ...(prev || []),
          ]);
        })
        .catch(errorHandler);
    },
    [user, clients, errorHandler]
  );

  // --- HANDLER: DELETE ATTENDANCE ---
  const handleDeletion = async (attendanceId: string) => {
    snackbarContext.alert("info", t("Deleting record..."));
    if (!user) {
      throw new Error("Only admins can delete an attendance record");
    }
    await session.deleteAttendance(user.uid, attendanceId);
    setAttendanceWithPhoto((prev) =>
      prev?.filter((att) => att.uid !== attendanceId)
    );
  };

  // --- LOADING STATE ---
  if (!attendancesWithPhoto) {
    return <AppSkeleton />;
  }

  // --- Check if today is a training day ---
  const today = new Date();
  const todayWeekday = getDay(today); // 0 = Sunday, 1 = Monday, ...
  const trainingDays = isFullSkillsTrainingPlanGuard(user.skillsTrainingPlan)
    ? user.skillsTrainingPlan.availableDays
    : undefined;
  const isTrainingDay = trainingDays && trainingDays.includes(todayWeekday);

  // --- FILTER & SORT ATTENDANCE ---
  const sorted = sortBy(
    attendancesWithPhoto,
    (record) => new Date(record.date).getTime(),
    "desc"
  );

  // 1) Show only the last 20 days
  const now = new Date();

  // 2) Calculate stats:
  //    A) Days attended this week (starting last Sunday)
  const lastSunday = startOfWeek(now, { weekStartsOn: 0 }); // Sunday as start of week
  const daysAttendedThisWeek = attendancesWithPhoto.filter((rec) => {
    const recDate = new Date(rec.date);
    const nextSunday = addDays(lastSunday, 7);
    return (
      (isAfter(recDate, lastSunday) || isEqual(recDate, lastSunday)) &&
      isBefore(recDate, nextSunday)
    );
  }).length;

  //    B) Total hours of attendance
  const totalHours = attendancesWithPhoto.reduce(
    (sum, rec) => sum + (rec.duration || 0),
    0
  );
  //    C) Reimbursement (simple example: $15/hour)
  const REIMBURSEMENT_RATE = 15;
  const totalReimbursement = totalHours * REIMBURSEMENT_RATE;
  //    D) Hours remaining out of 160
  const HOURS_TARGET = 160;
  const hoursLeft =
    HOURS_TARGET - totalHours > 0 ? HOURS_TARGET - totalHours : 0;

  return (
    <Stack spacing={theme.spacing(2)}>
      {/* --- TRAINING DAY CHECK-IN CARD --- */}
      <Card sx={(theme) => ({ backgroundColor: theme.palette.primary.dark })}>
        <CardHeader
          title={
            isTrainingDay
              ? t("Today is a Training Day!")
              : t("Have you trained today?")
          }
          sx={{ color: theme.palette.primary.contrastText }}
        />
        <CardContent>
          {trainingDays && (
            <Box marginY={2}>
              <Typography variant="body2" color="white">
                {t("Your Training Days:")}
              </Typography>
              <Typography color="white" fontWeight="bold">
                {trainingDays.map((d) => WEEKDAY_MAP[d]).join(", ")}
              </Typography>
            </Box>
          )}
          <Button
            variant="outlined"
            size="large"
            onClick={() => setIsOpen(true)}
            sx={{ backgroundColor: "white" }}
            startIcon={<Login />}
          >
            {t("Check In")}
          </Button>
        </CardContent>
      </Card>
      {/* --- ALL STATISTICS IN ONE CARD --- */}
      <Card>
        <CardHeader title={t("Your Journey So Far")} />
        <CardContent>
          <Grid container spacing={2}>
            {/* Total Sessions Attended */}
            <Grid item xs={6} md={3}>
              <Stack alignItems="center">
                <CalendarMonth sx={{ fontSize: 40, color: "primary.main" }} />
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {sorted.length}
                </Typography>
                <Typography variant="body2">{t("Total Sessions")}</Typography>
              </Stack>
            </Grid>

            {/* Days Attended This Week */}
            <Grid item xs={6} md={3}>
              <Stack alignItems="center">
                <CalendarMonth sx={{ fontSize: 40, color: "primary.main" }} />
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {daysAttendedThisWeek}
                </Typography>
                <Typography variant="body2">
                  {t("Days Attended This Week")}
                </Typography>
              </Stack>
            </Grid>

            {/* Total Reimbursement */}
            <Grid item xs={6} md={3}>
              <Stack alignItems="center">
                <MonetizationOn sx={{ fontSize: 40, color: "success.main" }} />
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {`$${totalReimbursement.toFixed(2)}`}
                </Typography>
                <Typography variant="body2">
                  {t("Total Reimbursement")}
                </Typography>
              </Stack>
            </Grid>

            {/* Hours Left */}
            <Grid item xs={6} md={3}>
              <Stack alignItems="center">
                <AccessTime sx={{ fontSize: 40, color: "secondary.main" }} />
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {hoursLeft}
                </Typography>
                <Typography variant="body2">{t("Hours Left")}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* --- ADD ATTENDANCE BUTTON --- */}
      {/* <Box sx={{ marginLeft: "auto" }}>
        <Button
          variant="outlined"
          onClick={() => setIsOpen(true)}
          startIcon={<AddAPhoto />}
        >
          {t("Add")}
        </Button>
      </Box> */}
      {/* --- ATTENDANCE CARDS (LAST 20 DAYS) --- */}
      <Card>
        <CardHeader title={t("Your Training Activity")} />
        <CardContent>
          <Stack
            direction={{
              md: "row",
              xs: "column",
            }}
            spacing={2}
            flexWrap="wrap"
          >
            {sorted.map((record) => (
              <Card variant="outlined" key={record.uid}>
                {record.file && (
                  <CardMedia
                    component="img"
                    src={URL.createObjectURL(record.file)}
                    height={100}
                    sx={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                )}
                <CardHeader
                  title={formatOptionalTimestamp(record.date)}
                  subheader={
                    record.duration
                      ? t("{{duration}} hours", {
                          duration: record.duration,
                        })
                      : undefined
                  }
                  action={
                    isAdmin && (
                      <IconButton onClick={() => handleDeletion(record.uid)}>
                        <Delete />
                      </IconButton>
                    )
                  }
                />
              </Card>
            ))}
          </Stack>
        </CardContent>
      </Card>
      {/* --- MODAL FOR ADDING ATTENDANCE --- */}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullScreen={isMobileOnly}
      >
        <DialogContent>
          <Box
            maxWidth={600}
            height="100%"
            margin="auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <RangeAttendanceForm handleSubmission={handleSubmission} />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
