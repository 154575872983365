import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type ConfirmDialogProps = {
  open: boolean;
  title?: string;
  description?: string;
  onConfirm: () => void;
  onCancel: () => void;
  buttonColor?: ButtonProps["color"];
};

const ConfirmDialog = ({
  open,
  title,
  description,
  onConfirm,
  onCancel,
  buttonColor,
}: ConfirmDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title || t("Confirm action?")}</DialogTitle>
      <DialogContent>
        {description || t("This action is irreversible.")}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t("Cancel")}</Button>
        <Button onClick={onConfirm} color={buttonColor} autoFocus>
          {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
