import { AirSharp, BookTwoTone } from "@mui/icons-material";
import { Box, Card, CardContent, Grid, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactJoyride from "react-joyride";
import { useNavigate, useSearchParams } from "react-router-dom";
import VideoAskActionItemCard from "src/components/Card/VideoAskActionItemCard";
import { Header, PaddedLayout } from "src/components/Layout";
import PaddedLayoutContainer from "src/components/Layout/PaddedLayoutContainer";
import Template from "src/components/Layout/Template";
import { Header20 } from "src/components/Typography";
import useJoyrideTutorial from "src/hooks/useJoyrideTutorial";
import { useCurrentUser } from "src/SessionBoundary";
import { CLP_ID } from "src/utils/constants";
import featureEnabled from "src/utils/featureEnabled";
import GradesTab from "../ClientScreen/GradesTab";
import StudentPlanTab from "../ClientScreen/StudentPlanTab/StudentPlanTab";
import VideoAskSchema from "../OnboardingScreen/videoAskIds.enum";
import AdminParticipantChat from "./AdminParticipantChatTab/AdminParticipantChatTab";
import DashboardCohortAnnouncements from "./DashboardCohortAnnouncements";
import DashboardTabs, { DashboardTab } from "./DashboardTabs";
import DashboardTrainingModuleCard from "./DashboardTrainingModuleCard";
import DashboardTrainingProgressCard from "./DashboardTrainingProgressCard";
import ResourceTab from "./ProfileScreen";
import PWASnackbar from "./PWASnackbar";
import RangeTab from "./RangeTab/RangeTab";
import SkillsTrainingDashboard from "./RangeTab/SkillsTrainingDashboard";
import SavedTab from "./SavedTab";
import TrainingProgressStepper from "./TrainingProgressStepper";

// ID of  CLP is currently hardcoded
export const DashboardScreen = () => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({
    tab: "home",
  });
  const [tab, setTab] = useState<DashboardTab>(
    (searchParams.get("tab") as DashboardTab) || "home"
  );
  const theme = useTheme();
  const { isTutorialRunning, joyrideSteps, handleJoyrideCallback } =
    useJoyrideTutorial({
      steps: [
        {
          disableBeacon: true,
          target: ".training-stepper",
          content: t(
            "Welcome to your Emerge dashboard 🚀! This is where you can track the high-level progress of your training."
          ),
        },
        {
          disableBeacon: true,
          target: ".training-card",
          content: t(
            "Your goal now is to finish the online training. Once you get your permit, we will send you to a partner school for in-person training."
          ),
        },
        {
          disableBeacon: true,
          target: ".success-plan-card",
          content: t(
            "This is your Success Plan. It tracks the milestones you need to complete outside of the app to succeed."
          ),
        },
        {
          disableBeacon: true,
          target: ".training-card",
          content: t(
            "The next step is for you to finish the first unit! Click here to keep going."
          ),
        },
      ],
    });

  const handleTabChange = (value: DashboardTab) => {
    setTab(value);
    setSearchParams((prev) => {
      prev.set("tab", value);
      return prev;
    });
  };

  useEffect(() => {
    if (
      user.enrollmentStatus === "skills_training" ||
      user.enrollmentStatus === "completed_skills_training"
    )
      setTab("range");
  }, [user.enrollmentStatus]);

  const navigateToCourse = () => navigate(`/courses/${CLP_ID}`);

  return (
    <Template>
      <PWASnackbar />
      <ReactJoyride
        steps={joyrideSteps}
        continuous
        run={isTutorialRunning}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: theme.palette.primary.main,
          },
        }}
      />
      <Header title="CDL Course" section={`Welcome back, ${user.firstName}!`}>
        <DashboardTabs onChange={handleTabChange} value={tab} />
      </Header>
      {user.cohortId && user.organizationId && (
        <PaddedLayout>
          <DashboardCohortAnnouncements
            cohort={{ uid: user.cohortId, organizationId: user.organizationId }}
          />
        </PaddedLayout>
      )}
      {tab === "home" && (
        <Box>
          <PaddedLayout noYMargin>
            <PaddedLayoutContainer>
              <Box className="training-stepper">
                <TrainingProgressStepper user={user} />
              </Box>
            </PaddedLayoutContainer>
          </PaddedLayout>

          <PaddedLayout>
            <PaddedLayoutContainer>
              <Box className="training-card">
                {process.env.NODE_ENV === "development" ||
                (featureEnabled("SKILLS_TRAINING_REVAMP") &&
                  (user.enrollmentStatus === "skills_training" ||
                    user.enrollmentStatus === "completed_skills_training")) ? (
                  <SkillsTrainingDashboard user={user} />
                ) : (
                  <div />
                )}

                {user.theoryTrainingPlan ? (
                  <DashboardTrainingModuleCard />
                ) : (
                  <DashboardTrainingProgressCard
                    client={user}
                    handleClick={navigateToCourse}
                  />
                )}
              </Box>

              <Box marginTop={4}>
                <Grid container justifyContent="space-between">
                  {(user.enrollmentStatus === "skills_training" ||
                    user.enrollmentStatus === "completed_skills_training") && (
                    <Grid item xs={12} md={6}>
                      {/* {process.env.NODE_ENV === "development" && (
                      <ResumeBuilderStatusCard user={user} />
                    )} */}
                      <Header20 my={2}>{t("Acing the CDL Exam")}</Header20>
                      <Stack>
                        <VideoAskActionItemCard
                          title={t("Pre-Trip Inspection Practice")}
                          description={t(
                            "Use this interactive guide to practice for the pre-trip inspection section of your CDL test. We'll email you feedback after you finish."
                          )}
                          item={VideoAskSchema.PRE_TRIP}
                          icon={BookTwoTone}
                        />
                        <VideoAskActionItemCard
                          title={t("Air Brakes Practice")}
                          description={t(
                            "Use this interactive guide to practice for the air brakes section of your CDL test. We'll email you feedback after you finish."
                          )}
                          item={VideoAskSchema.AIR_BRAKES}
                          icon={AirSharp}
                        />
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6} className="success-plan-card">
                    <Header20 my={2}>{t("Upcoming Milestones")}</Header20>
                    <StudentPlanTab
                      client={user}
                      hideButtons
                      onlyShowUpcomingMilestones
                      onReviewSuccessPlan={() => setTab("success_plan")}
                    />
                  </Grid>
                </Grid>
              </Box>
            </PaddedLayoutContainer>
          </PaddedLayout>
        </Box>
      )}
      {tab === "resources" && <ResourceTab />}
      {tab === "grades" && <GradesTab client={user} />}
      {tab === "saved" && <SavedTab />}
      {tab === "chat" && (
        <Box>
          <Box margin="auto" maxWidth={800}>
            <Card>
              <CardContent>
                <AdminParticipantChat client={user} />
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
      {tab === "success_plan" && (
        <PaddedLayout>
          <PaddedLayoutContainer>
            <StudentPlanTab client={user} />
          </PaddedLayoutContainer>
        </PaddedLayout>
      )}
      {tab === "range" && (
        <PaddedLayout>
          <RangeTab />
        </PaddedLayout>
      )}
    </Template>
  );
};

export default DashboardScreen;
