import { t } from "i18next";
import { BackgroundFormData } from "src/pages/BackgroundSurveyScreen/BackgroundSurveyView";
import { UserEligibilityData } from "src/types/User";

const LABEL_MAP: Record<
  keyof BackgroundFormData | keyof UserEligibilityData,
  string
> = {
  // intro demographic form
  driverLicenseReinstatementCost: t(
    "What is the total cost to clear fines, fees, or restrictions and reinstate a driver's license? (if any) "
  ),
  hasDriverLicense: t("Do you have an ACTIVE driver's license?"),
  hasEverHadDriverLicense: t(
    "Have you ever had a driver's license, even if it is not currently active?"
  ),
  hasToTakeMandatoryCourseToReinstateLicense: t(
    "Do you have to take mandatory courses to reinstate your license? "
  ),
  driverLicenseInactiveReason: t("Why is your driver's license inactive?"),
  driverLicenseNumber: t("What is your driver's license number?"),
  driverLicenseState: t("Which state issued your driver's license?"),
  driverLicenseIssueDate: t("When was your driver's license issued?"),
  driverLicenseExpirationDate: t("When is the expiration date?"),
  zipcode: t(
    "What is your current ZIP code of residence? If you don't have one, use your last ZIP code."
  ),
  hasMajorTrafficViolationOverPast3Years: t(
    "Have you had any major traffic violations in the past 3 years?"
  ),
  // demographic info
  maritalStatus: t("What is your marital status?"),
  education: t("What is your highest level of education attainment?"),
  ssn: t("What is your Social Security Number? (optional)"),
  race: t("Select the race(s) you identify with:"),
  gender: t("Select the gender you identify with:"),
  citizenship: t("Select the citizenship you identify with:"),
  disability: t("Do you identify as having a disability?"),
  disabilityType: t(
    "How would you describe your disability or chronic condition?"
  ),
  lgbtq: t("Do you identify as a part of the LGBTQIA+ community?"),
  isHispanic: t("Are you of Hispanic/Latino/a heritage?"),
  hasProficientEnglish: t("Do you speak English proficiently?"),
  isVeteran: "Veteran",
  isMilitarySpouse: "Is Military Partner",
  employmentStatus: t("What is your employment status?"),
  salary: t("What is your annual salary?"),
  hasBeenTerminated: t(
    "Within the last 12 months, have you been laid off from your job?"
  ),

  // Medical
  canPassVisionTest: t("Would you be able to pass a vision test?"),
  hasHistoryChronicalIllness: t(
    "Do you have any history of intense chronic head, heart, lung, or eye issues?"
  ),
  isTakingMedication: t(
    "Are you currently taking medications that would disqualify you from passing a Department of Transportation (DOT) drug test?"
  ),

  //   Benefits
  benefits: t(
    "Have you received any of the following forms of public assistance over the past six (6) months? Leave empty if none."
  ),
  numberDependents:
    "How many, if any, dependents (i.e children and minors) are you responsible for?",
  householdIndividualsNumber: t(
    "How many individuals live with you in your household?"
  ),
  totalIncomeLastSixMonths: t(
    "What was your total income in the last 6 months?"
  ),
  hasToPayChildSupport: t("Do you have to pay child support?"),
  isCurrentOnChildSupport: t(
    "Are you fully caught up on your child support payments?"
  ),
  //   incarceration
  isIncarcerated: t("Are you currently incarcerated or at a halfway house?"),
  hasBeenIncarcerated: t("Have you been incarcerated in the past?"),
  isProbationOrParole: t(
    "Are you currently on supervised probation or parole?"
  ),
  hasBeenProbationOrParole: t("Have you ever been on probation or parole?"),
  isJusticeImpacted: t("Have you been impacted by the justice system?"),
  hasDoneDiversionProgram: t(
    "Have you participated in a pretrial diversion program?"
  ),
  totalIncarcerationDuration: t(
    "What is the total amount of time (years) you've served in jails, counties, and prisons in your entire life?"
  ),
  lastReleased: t("When were you last released?"),
  numberDUIPast7Years: t(
    "How many DUI/DWI records have you had in the last 7 years?"
  ),
  hasSexOffense: t("Have you ever been charged or convicted of a Sex Offense?"),
  hasViolentOffense: t(
    "Have you ever been charted or convicted of a Violent Offense?"
  ),
  hasPendingCases: t("Do you have any pending cases?"),
  isFirstTimeOffender: t("Was this your first and only conviction?"),
  nextParoleHearingDate: t(
    "If applicable, when is your next parole hearing date?"
  ),
  nextCourtDate: t("When is your next court date?"),
  // pre-release programs
  housingUnit: t("What is your current housing unit?"),
  incarcerationIdentifier: t(
    "What is your identification number at the correctional facility?"
  ),
  numberDUIRecordLifetime: t(
    "How many DUI/DWI records do you have in your lifetime?"
  ),
  numberPointsDriverRecord: t(
    "How many points do you have on your driving record?"
  ),
  postReleaseCity: t(
    "In which city do you plan to live after you are released back into the community?"
  ),
  postReleaseHousing: t(
    "Who, if anyone, will you be living with upon being released?"
  ),
  postReleaseState: t(
    "In which state do you plan to live after you are released back into the community?"
  ),
  postReleaseTransportation: t("Do you have access to a car post-release?"),
  releaseDate: t("When is your release date?"),
  housing: t("What is your housing situation?"),
  transportation: t("What is your transportation situation?"),
  hasMoreThan4FelonyConvictions7Years: t(
    "Have you had more than 4 felony convictions in the last 7 years?"
  ),
  // User Eligibilty Data Type
  legalFirstName: t("Legal first name"),
  legalMiddleName: t("Legal middle name"),
  legalLastName: t("Legal last name?"),
  hasPreviousDriverLicense: t(
    "Have you previously had a driver’s license from another state or with a different number?"
  ),
  previousDriverLicenseNumber: t("Previous Driver's License Number"),
  previousDriverLicenseState: t("State of Previous License"),
  postalAddress: t(""), // this is not used in the form
};

export default LABEL_MAP;
